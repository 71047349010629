import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Product from './pages/Product';
import '@fortawesome/fontawesome-svg-core/styles.css';

function App() {
    return (
        <div className="min-h-screen overflow-y-scroll h-64 custom-scrollbar">
            <BrowserRouter>
                {/* header here */}
                <main>
                    <Routes>
                        <Route path="/" element={<Home />}/>
                        <Route path="/product/*" element={<Product />}/>
                        {/* <Route path="/route" element={<Element />}/> */}
                    </Routes>
                </main>
                {/* footer here */}
            </BrowserRouter>
        </div>
    );
}

export default App;
