import React from "react";
import EffectVideo from '../assets/videos/effect.mp4';

const AboutUs = () => {
    return (
        <>
            <section id="about-us" className="w-full m-8 xl:mt-[-150px] xxl:mt-[-200px]"></section>
            
            <section className="flex flex-row md:flex-row items-center justify-between p-8 mx-8 md:m-8 xl:mt-[-150px] xxl:mt-[-200px] min-h-screen bg-background_main text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    {/* Video on the left */}
                    <div className="w-full md:w-1/2 mb-4 md:mb-0 animte-slideInFromLeft hidden md:block">
                        <video
                            src={EffectVideo}
                            className="w-[250px] lg:w-[375px] h-auto mx-auto rounded-lg shadow-lg"
                            autoPlay
                            loop
                            muted
                        />
                    </div>

                    {/* text on the rigth */}
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <div className="animate-slideInFromAbove">
                                <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
                            <strong className="text-gradient-ai">About</strong> Us
                        </h1>
                        </div>
                        <div className="text-base sm:text-lg animate-slideInFromRight">
                            <p className="text-primary_text">
                                Tibb is founded with a vision to fill the gaps in healthcare by 
                                utilizing the advantages offered through AI, one solution at a time. 
                                We take healthcare personally and are making it personal rather 
                                than one size fits all. We are developing the tools and putting them 
                                in the hands of both Physicians and Patients.
                            </p>
                            <br />
                            <br />
                            <p className="text-primary_text">
                                We have mobilized a team of industry veterans both from Healthcare 
                                and Technology, dedicated to transforming the landscape of healthcare. 
                                Our goal is to provide advanced, evidence based and comprehensive care 
                                easily accessible to humanity in every part of the world.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
