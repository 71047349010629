import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScroll = (e, targetId) => {
        e.preventDefault(); // Prevent default link behavior
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Close menu if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.menu-container') && !event.target.closest('.menu-icon')) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="text-primary_text pt-3 px-3">
            <div className="container mx-auto max-h-[100px] flex justify-between items-center py-4">
                <div className="text-xl font-bold animate-slideInFromLeft">
                </div>
                <nav className="hidden md:block">
                    <ul className="flex space-x-3 animate-slideInFromRight">
                        <li>
                            <a
                                href="/"
                                className="p-4 transition ease-in-out hover:text-primary_text_hover duration-300 hover:underline"
                            >
                                Home
                            </a>
                        </li>
                        <li>
                            <a
                                href="#why-tibb"
                                onClick={(e) => handleScroll(e, "why-tibb")}
                                className="p-4 transition ease-in-out hover:text-primary_text_hover duration-300 hover:underline"
                            >
                                Why Tibb Scribe
                            </a>
                        </li>
                        <li>
                            <a
                                href="#key-features"
                                onClick={(e) => handleScroll(e, "key-features")}
                                className="p-4 transition ease-in-out hover:text-primary_text_hover duration-300 hover:underline"
                            >
                                Our Features
                            </a>
                        </li>
                        <li>
                            <a
                                href="#download-now"
                                onClick={(e) => handleScroll(e, "download-now")}
                                className="p-4 transition ease-in-out hover:text-primary_text_hover duration-300 hover:underline"
                            >
                                Download Now !
                            </a>
                        </li>
                    </ul>
                </nav>

                {/* Mobile Menu Icon */}
                <div className="md:hidden menu-icon m-2 p-2 text-3xl z-50 transition-transform animate-slideInFromRight" onClick={toggleMenu}>
                    {isMenuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} /> }
                </div>
            </div>

            {/* Sliding Mobile Menu */}
            <div
                className={`fixed mt-0 top-0 right-0 z-40 h-full w-64 bg-gradient-to-b from-scribe-gradient-start via-scribe-gradient-middle1 to-scribe-gradient-end shadow-lg transform ${
                    isMenuOpen ? "translate-x-0" : "translate-x-full"
                } transition-transform duration-300 ease-in-out menu-container`}
            >
                <ul className="mt-10 p-6 space-y-4">
                    <li>
                        <a
                            href="/"
                            onClick={() => setIsMenuOpen(false)}
                            className="block border-b-2 text-lg transition ease-in-out hover:text-primary_text_hover duration-300"
                        >
                            Home
                        </a>
                    </li>
                    <li>
                        <a
                            href="#why-tibb"
                            onClick={(e) => {
                                setIsMenuOpen(false);
                                handleScroll(e, "why-tibb");
                            }}
                            className="block border-b-2 text-lg transition ease-in-out hover:text-primary_text_hover duration-300"
                        >
                            Why Tibb Scribe
                        </a>
                    </li>
                    <li>
                        <a
                            href="#key-features"
                            onClick={(e) => {
                                setIsMenuOpen(false);
                                handleScroll(e, "key-features");
                            }}
                            className="block border-b-2 text-lg transition ease-in-out hover:text-primary_text_hover duration-300"
                        >
                            Our Features
                        </a>
                    </li>
                    <li>
                        <a
                            href="#download-now"
                            onClick={(e) => {
                                setIsMenuOpen(false);
                                handleScroll(e, "download-now");
                            }}
                            className="block border-b-2 text-lg transition ease-in-out hover:text-primary_text_hover duration-300"
                        >
                            Download Now!
                        </a>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default Header;
