import React from "react";
import ProductCard from "./ProductCard";
import { useNavigate } from 'react-router-dom';
import Logo from "../assets/images/logo-2.png";

const Products = () => {
    const navigate = useNavigate();
    const productData = [
        {
            id: 1,
            name: "Tibb SCRIBE",
            description:
                "Tibb's AI Scribe captures clinical interactions and converts them into Clinical and SOAP Notes, which are automatically integrated into the EMR.",
            details:
                "Tibb Scribe uses customized AI models to understand clinical conversations and automate workflow.",
            borderColor: "border-product_border_2",
            classNames: 'bg-gradient-to-r from-scribe-gradient-start to-[#8e51a3]',
            route: '/product/tibb-scribe',
        },
        {
            id: 2,
            name: "Tibb 360 for Patients",
            description:
                "Tibb 360 is a platform and app that maintains comprehensive patient health records, enabling sharing with care teams and specialists. It supports telemedicine appointments, lab results, prescriptions, and health alerts, while seamlessly connecting to wearable devices.",
            details:
                "Tibb 360 consolidates patient health records for informed decision-making.",
            borderColor: "border-product_border_3",
            classNames: 'bg-gradient-to-r from-goldenLight_2 to-[#005e6b]',
            route: '/product/tibb-360',
        },
        {
            id: 3,
            name: "Tibb 360 for Physicians",
            description:
                "This platform connects physicians with patients, offering a holistic view of patient health over time. Tibb Advisor, analyzes patient conditions and recommends accurate diagnoses and treatment plans.",
            details:
                "Progress Path enhances mental health treatment outcomes through customized checkpoints.",
            borderColor: "border-product_border_6",
            classNames: 'bg-gradient-to-r from-blueDark_1 to-[#ff816d]',
            route: '/product/tibb-360-doc',
        },
        {
            id: 4,
            name: "Tibb SPOT",
            description:
                "Tibb SPOT is an edge AI device installed in examination rooms to automate patient interactions. It captures instructions for nursing staff, medication schedules, and surgical procedures.",
            details:
                "Tibb SPOT is an edge AI device specifically designed to be installed in healthcare facilities. It offers ultimate security and enhanced automation features for conducting voice based clinical operations in closed and limited connectivity settings.",
            borderColor: "border-product_border_5",
            classNames: 'bg-gradient-to-r from-purple-800 to-[#fb3f6e]',
            route: '/product/tibb-spot',
        },
    ];

    const handleCardClick = (productRoute) => {
        // setSelectedProduct(product);
        navigate(productRoute);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <section id="our-products"></section>

            <section
                id="our-products"
                className="flex flex-col md:flex-col min-h-screen items-center justify-between p-4 md:p-8 md:mx-8 bg-background_main text-primary_text"
            >
                <h1 className="text-2xl font-bold mb-4 block md:hidden">
                    Our <strong className="text-gradient-ai">Products</strong>
                </h1>

                <div className="flex flex-row w-full md:space-x-8">
                    {/* Left Column: 1/3 width with two rows */}
                    <div className="md:flex md:items-center md:justify-between md:w-1/3 md:space-y-8 hidden md:block">
                        <div className="w-full flex flex-col items-center justify-between space-y-9">
                            <img className="animate-slideInFromLeft" src={Logo} alt="Tibb Logo" />
                            <h1 className="text-3xl md:text-[38px] font-bold mb-12 animate-slideInFromRight">
                                Our <strong className="text-gradient-ai">Products</strong>
                            </h1>
                        </div>
                    </div>

                    {/* Right Column: 2/3 width with two rows and two columns per row */}
                    <div className="w-full md:w-2/3 md:grid md:grid-cols-2 md:gap-4 md:gap-8 hidden md:block">
                        <div className="w-full flex flex-col items-center justify-between">
                            <ProductCard
                                name={productData[0].name}
                                description={productData[0].description}
                                details={productData[0].details}
                                borderColor={productData[0].borderColor}
                                classNames={productData[0].classNames}
                                onClick={() => handleCardClick(productData[0].route)}
                            />
                        </div>
                        <div className="w-full flex flex-col items-center justify-between">
                            <ProductCard
                                name={productData[1].name}
                                description={productData[1].description}
                                details={productData[1].details}
                                borderColor={productData[1].borderColor}
                                classNames={productData[1].classNames}
                                onClick={() => handleCardClick(productData[1].route)}
                            />
                        </div>
                        <div className="w-full flex flex-col items-center justify-between">
                            <ProductCard
                                name={productData[2].name}
                                description={productData[2].description}
                                details={productData[2].details}
                                borderColor={productData[2].borderColor}
                                classNames={productData[2].classNames}
                                onClick={() => handleCardClick(productData[2].route)}
                            />
                        </div>
                        <div className="w-full flex flex-col items-center justify-between">
                            <ProductCard
                                name={productData[3].name}
                                description={productData[3].description}
                                details={productData[3].details}
                                borderColor={productData[3].borderColor}
                                classNames={productData[3].classNames}
                                onClick={() => handleCardClick(productData[3].route)}
                            />
                        </div>
                    </div>

                    {/* Right Column: 2/3 width with two rows and two columns per row */}
                    <div className="flex flex-col w-full md:hidden space-y-0 px-4">
                    {/* <div className="flex w-full overflow-x-auto custom-scrollbar md:hidden space-x-4 px-4"> */}
                        <div className="min-w-[80%] flex-shrink-0">
                            <ProductCard
                                name={productData[0].name}
                                description={productData[0].description}
                                details={productData[0].details}
                                borderColor={productData[0].borderColor}
                                classNames={productData[0].classNames}
                                onClick={() => handleCardClick(productData[0].route)}
                            />
                        </div>
                        <div className="min-w-[80%] flex-shrink-0">
                            <ProductCard
                                name={productData[1].name}
                                description={productData[1].description}
                                details={productData[1].details}
                                borderColor={productData[1].borderColor}
                                classNames={productData[1].classNames}
                                onClick={() => handleCardClick(productData[1].route)}
                            />
                        </div>
                        <div className="min-w-[80%] flex-shrink-0">
                            <ProductCard
                                name={productData[2].name}
                                description={productData[2].description}
                                details={productData[2].details}
                                borderColor={productData[2].borderColor}
                                classNames={productData[2].classNames}
                                onClick={() => handleCardClick(productData[2].route)}
                            />
                        </div>
                        <div className="min-w-[80%] flex-shrink-0">
                            <ProductCard
                                name={productData[3].name}
                                description={productData[3].description}
                                details={productData[3].details}
                                borderColor={productData[3].borderColor}
                                classNames={productData[3].classNames}
                                onClick={() => handleCardClick(productData[3].route)}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Products;
