import React from "react";
import Logo from '../../assets/images/logo-2.png';
import HIPAA_Logo from '../../assets/images/hipaa-icon-3.png';

const Footer = () => {
    return (
        <footer className="text-primary_text md:px-8 md:pt-8">
            <div className="container mx-auto pt-0 py-8">
                <hr className="my-4 border-grey_level_5" />
                <div className="flex flex-col md:flex-row justify-between items-center">
                    <div className="flex md:flex-row text-xl font-bold justify-between items-center">
                        <div>
                            <a href="/" className="hover:primary_text_hover">
                                {/* Tibb */}
                                <img className="max-w-[150px] sm:max-w-[200px] p-6" src={Logo} alt="Tibb" />
                            </a>
                        </div>
                        <div>
                            {/* HIPAA Compliant */}
                            <img className="max-w-[150px] sm:max-w-[200px] p-6" src={HIPAA_Logo} alt="Tibb" />
                        </div>
                    </div>
                    <nav className="mt-6 md:mt-0">
                        <ul className="flex flex-col items-center md:items-end space-y-2">
                            {/* Navigation URLs here */}
                            <li>
                                <a
                                    href="/privacy-policy"
                                    className="p-4 hover:text-primary_text_hover hover:underline"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms-and-conditions"
                                    className="p-4 hover:text-primary_text_hover hover:underline"
                                >
                                    Terms & Conditions
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/baa"
                                    className="p-4 hover:text-primary_text_hover hover:underline"
                                >
                                    BAA
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="flex flex-col md:flex-col mt-8 md:m-0 justify-between items-center text-gray-400">
                    <div className="text-xs">
                        <p>&copy; {new Date().getFullYear()} <a href="https://tibb.io/" className="cursor-pointer hover:underline hover:text-gray-200">Tibb Labs</a>. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
