import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const CommingSoon = () => {
    return (
        <div className="bg-background_main">
            <Header />
            <section className="flex flex-col md:flex-row min-h-screen items-center justify-between p-8 m-8 bg-background_main text-primary_text">
                <div className="w-full h-full flex flex-col items-center text-center md:text-left">
                    <h2 className="text-5xl font-bold mb-4">
                        Comming <strong className="text-gradient-ai">Soon</strong>!
                    </h2>
                    <p className="text-lg text-primary_text">
                        Stay <strong  className="text-text_strong">Tuned</strong> for furhter updates!
                    </p>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default CommingSoon;
