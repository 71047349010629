import React from "react";
import Header from "../components/TibbScribe/Header";
import Footer from "../components/TibbScribe/Footer";
import KeyFeatures from "../components/TibbScribe/KeyFeatures";
import WhyTibb from "../components/TibbScribe/WhyTibb";
import DownloadNow from "../components/TibbScribe/DownloadNow";
import SubscribeNow from "../components/TibbScribe/SubscribeNow";
import HeroScribe from "../components/TibbScribe/Hero";

const TibbScribe = () => {
    return (
        <div className="bg-gradient-to-b from-scribe-gradient-start via-scribe-gradient-middle1 to-scribe-gradient-end">
            <div className="xxl:mx-0">
                <Header />
                <HeroScribe />
                <WhyTibb />
                <KeyFeatures />
                <div className="min-h-screen">
                    <SubscribeNow />
                    <DownloadNow />
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default TibbScribe;
