import React from "react";
import HeroImg from '../assets/images/hero.png';

const Hero = () => {
    return (
        <section className="flex flex-col md:flex-row items-center justify-between md:min-h-screen p-8 md:mx-0 md:px-8 md:py-0 bg-background_main text-primary_text">
            <div className="w-full block md:hidden animate-slideInFromRight_F">
                <img
                    src={HeroImg}
                    alt="Tibb AI"
                    className="w-[450px] h-auto mx-auto rounded-lg shadow-lg"
                />
            </div>
            <div className="flex flex-col md:flex-row items-center justify-between md:mt-[-150px] md:mx-4">
                {/* Tagline on the left */}
                <div className="w-full md:w-1/2 text-center md:text-left animate-slideInFromLeft_F">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
                        <strong className="text-gradient-ai">Digitizing</strong> Healthcare with <span className="text-gradient-ai">AI</span>
                    </h2>
                    <p className="text-sm sm:text-lg text-primary_text">
                        At Tibb we are developing modern <strong  className="text-text_strong">healthcare</strong> applications 
                        and infusing them with <strong className="text-text_strong">AI</strong> - A New generation of solutions that are not only functional 
                        but also <strong className="text-text_strong">user-friendly</strong>, ensuring a seamless experience.
                    </p>
                </div>
                {/* Image on the right */}
                <div className="w-1/2 hidden md:block md:flex justify-end animate-slideInFromRight_F">
                    <img
                        src={HeroImg}
                        alt="Tibb AI"
                        className="w-2/3 h-auto lg:w-[500px] xxxl:mx-auto rounded-lg shadow-lg"
                    />
                </div>
            </div>
        </section>
    );
};

export default Hero;
