import React from "react";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import Products from "../components/Products";
import ContactUs from "../components/ContactUs";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <div className="bg-background_main">
            <div className="xxl:mx-2">
                <Header />
                <Hero />
                <AboutUs />
                <Products />
                <ContactUs />
                <Footer />
            </div>
        </div>
    );
};

export default Home;
