import React from "react";
import { useEffect } from "react";

const SignUpPopUp2 = ({ show, onClose }) => {
    // Close the pop-up when clicking outside of it
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.id === "popup-overlay") {
                onClose();
            }
        };

        window.addEventListener("click", handleOutsideClick);
        return () => {
            window.removeEventListener("click", handleOutsideClick);
        };
    }, [onClose]);

    if (!show) return null;

    return(
        <>
            <section
                id="popup-overlay"
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center w-full z-50"
            >
                <div className="bg-white rounded-3xl shadow-lg p-8 w-4/5 md:w-full max-w-2xl animate-slideInFromLeft_F2 md:animate-slideInFromAbove_F max-h-[80vh] overflow-auto md:max-h-full">
                    <h2 className="text-3xl font-bold text-center">Sign Up</h2>
                    <hr className="w-full my-4 border-t border-gray-100" />
                    <form className="space-y-4">
                        {/* First Name and Last Name Row */}
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex-1">
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-700"
                                    placeholder="* First Name"
                                    required
                                />
                            </div>
                            <div className="flex-1">
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-700"
                                    placeholder="* Last Name"
                                    required
                                />
                            </div>
                        </div>

                        {/* Email Field */}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-700"
                                placeholder="* Email"
                                required
                            />
                        </div>

                        {/* Password and confirm password */}
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex-1">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-700"
                                    placeholder="* Password"
                                    required
                                />
                            </div>
                            <div className="flex-1">
                                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                    Confirm Password
                                </label>
                                <input
                                    type="text"
                                    id="confirmPassword"
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-700"
                                    placeholder="* Confirm Password"
                                    required
                                />
                            </div>
                        </div>

                        {/* Practice Name and Medical Speciality */}
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex-1">
                                <label htmlFor="practiceName" className="block text-sm font-medium text-gray-700">
                                    Practice Name
                                </label>
                                <input
                                    type="text"
                                    id="practiceName"
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-700"
                                    placeholder="* Practice Name"
                                    required
                                />
                            </div>
                            <div className="flex-1">
                                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                    Medical Speciality
                                </label>
                                <select
                                    id="medicalSpeciality"
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-700"
                                    required
                                >
                                    <option value="">Select a speciality</option>
                                    <option value="Addiction Medicine">Addiction Medicine</option>
                                    <option value="Behavioral Health">Behavioral Health</option>
                                    <option value="Cardiology">Cardiology</option>
                                    <option value="Chiropractor">Chiropractor</option>
                                    <option value="Counseling">Counseling</option>
                                    <option value="Dentistry">Dentistry</option>
                                    <option value="Dermatology">Dermatology</option>
                                    <option value="Diagnostic Radiology">Diagnostic Radiology</option>
                                    <option value="Endocrinology">Endocrinology</option>
                                    <option value="Family Medicine">Family Medicine</option>
                                    <option value="Gastroenterology">Gastroenterology</option>
                                    <option value="Hematology">Hematology</option>
                                    <option value="Hospice and Palliative Medicine">Hospice and Palliative Medicine</option>
                                    <option value="Infectious Disease">Infectious Disease</option>
                                    <option value="Internal Medicine">Internal Medicine</option>
                                    <option value="Occupational Therapy">Occupational Therapy</option>
                                    <option value="Oncology">Oncology</option>
                                    <option value="Ophthalmology">Ophthalmology</option>
                                    <option value="Orthopedic Surgery">Orthopedic Surgery</option>
                                    <option value="Otolaryngology-Head and Neck Surgery">Otolaryngology-Head and Neck Surgery</option>
                                    <option value="Pain Medicine">Pain Medicine</option>
                                    <option value="Pediatrics">Pediatrics</option>
                                    <option value="Physical Medicine and Rehabilitation">Physical Medicine and Rehabilitation</option>
                                    <option value="Physical Therapy">Physical Therapy</option>
                                    <option value="Physician Assistant">Physician Assistant</option>
                                    <option value="Plastic Surgery">Plastic Surgery</option>
                                    <option value="Podiatry">Podiatry</option>
                                    <option value="Preventive Medicine">Preventive Medicine</option>
                                    <option value="Psychiatry">Psychiatry</option>
                                    <option value="Psychology">Psychology</option>
                                    <option value="Pulmonology">Pulmonology</option>
                                    <option value="Radiation Oncology">Radiation Oncology</option>
                                    <option value="Rheumatology">Rheumatology</option>
                                    <option value="Sleep Medicine">Sleep Medicine</option>
                                    <option value="Speech Language Pathology">Speech Language Pathology</option>
                                    <option value="Sports Medicine">Sports Medicine</option>
                                    <option value="Therapy">Therapy</option>
                                    <option value="Thoracic Surgery">Thoracic Surgery</option>
                                    <option value="Urgent Care">Urgent Care</option>
                                    <option value="Urology">Urology</option>
                                </select>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="w-full bg-red-700 text-white font-semibold py-2 rounded-md hover:bg-red-800 transition"
                        >
                            Sign Up
                        </button>

                        {/* OR divider */}
                        <div className="flex items-center my-4">
                            <hr className="flex-grow border-t border-gray-300" />
                            <span className="px-2 text-sm text-gray-500">OR</span>
                            <hr className="flex-grow border-t border-gray-300" />
                        </div>

                        {/* Additional Sign Up Options */}
                        <div className="flex gap-4">
                            <button
                                type="button"
                                className="flex-1 bg-blue-600 text-white font-semibold py-2 rounded-md hover:bg-blue-700 transition"
                            >
                                Sign Up with Google
                            </button>
                            <button
                                type="button"
                                className="flex-1 bg-gray-800 text-white font-semibold py-2 rounded-md hover:bg-gray-900 transition"
                            >
                                Sign Up with Your EMR
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default SignUpPopUp2;
