import React from "react";
import googlePlayImg from '../../assets/images/google-play-badge-2.png';
import iosImg from '../../assets/images/app-store-badge-2.png';

const DownloadNow = () => {
    return (
        <>
            <section id="download-now" className="flex flex-col items-center justify-between pt-4 py-2 mt-10 md:p-8 md:m-8 xxl:mt-0 text-primary_text">
                <h1 className="text-2xl md:text-4xl font-bold text-center mb-0 animate-slideInFromAbove">
                    Download <strong className="text-gradient-ai">Now</strong>
                </h1>
            </section>

            <section className="flex flex-row items-center justify-around p-0 m-8 rounded-xl text-primary_text">
                {/* Android Download Button */}
                <div className="flex items-center">
                    <a
                        href="https://play.google.com/store" // To be replaced with actual Android download link
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-lg text-white px-4 py-2 rounded-lg font-semibold animate-slideInFromLeft"
                    >
                        <img src={googlePlayImg} alt="Android" className="w-full h-auto border-2 border-transparent hover:border-2 rounded-xl hover:scale-105 hover:border-grey_level_4 transition" />
                    </a>
                </div>

                {/* iOS Download Button */}
                <div className="flex items-center">
                    <a
                        href="https://apps.apple.com" // To be replace with actual iOS download link
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-lg text-white px-4 py-2 rounded-lg font-semibold animate-slideInFromRight"
                    >
                        <img src={iosImg} alt="ios" className="w-full h-auto border-2 border-transparent hover:border-2 rounded-xl hover:scale-105 hover:border-grey_level_4 transition" />
                    </a>
                </div>
            </section>
        </>
    );
};

export default DownloadNow;
